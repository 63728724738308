@import '../../common/_variables.module.scss';

.loading-bar-container {
  position: relative;
  height: 100%;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.05);
  }
  .progress-bar {
    position: absolute;
    top: calc(50% - 2px);
    left: calc(50% - 25px);
    width: 50px;
    &.MuiLinearProgress-colorPrimary {
      background-color: $color-grey-10;
    }
    .MuiLinearProgress-barColorPrimary {
      background-color: $color-green;
    }
  }
}
