@import '../../common/_variables.module.scss';

.event-list {
  &__text {
    color: $color-grey-main;
    font-family: $font-family-main;
    font-weight: $font-weight--regular;
    font-size: 14px;
    line-height: 16px;
  }
  &__header {
    background: $color-grey-50;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px 20px 0px 0px;
  }
  &__body {
    border-radius: 0px 0px 20px 20px;
    background: $color-white;
    overflow: hidden;
    padding-top: 5px;
    padding-bottom: 10px;
  }

  &__toggleBtn {
    width: 50%;
    float: left;
    flex: 2;

    .switch input[type='checkbox']:not(:checked) + .content .toggle {
      width: 50%;
      transition: 0.5s ease;
      margin-left: 3px;
      height: 1.6rem;
    }
  }
  &__actionBtns {
    width: 50%;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    button {
      padding: 5px;
    }
  }
  &__actionIcon {
    color: $color-white;
    cursor: pointer;
  }
  &__items {
    margin: 0px;
    padding: 0px;
    height: 250px;
    overflow-y: auto;
    .event-label {
      padding: 0.6rem;
      display: flex;
      justify-content: space-between;
      width: 100%;
      user-select: none;
      background: $color-white;
      box-sizing: border-box;
      border-bottom: 2px solid hsl(0, 0%, 93%);
      cursor: pointer;
      &__priority {
        width: 20%;
      }
      &__name {
        width: 45%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &__date {
        width: 35%;
      }
    }
  }
  &__item {
    list-style: none;
    & .hidden {
      display: none;
    }
    & .hidden:checked + label {
      border-left: 5px solid $color-green;
    }
  }
  .name {
    display: flex;
    width: 45%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .add-icon {
      color: $color-green;
      margin-right: 0.5rem;
    }
  }
}
