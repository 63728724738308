.dropdownList {
  &__button {
    padding: 0 !important;
    min-width: 1px !important;

    &:hover {
      background-color: unset !important;
    }
  }
}
