@import '../../common/_common.scss';
.menu-button {
  li {
    cursor: default;
  }
  &__container {
    width: 100%;
    display: flex;
  }
  &__text {
    width: 70%;
    span {
      @include primary-text;
    }
  }
  &__icon {
    width: 30%;
    span {
      @include primary-text;
      padding: 0;
    }
  }
}
