@import '../../../common/_common.scss';

.help-container {
  width: 100%;
  max-height: 770px;
  align-items: flex-start;
}

.help-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btns-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 3rem;
  height: 125px;
}

.search-container {
  position: relative;

  .search-icon {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .close-icon {
    position: absolute;
    right: 12px;
    top: 11px;
    cursor: pointer;
  }

  input {
    height: 25px;
    width: 20rem;
    padding: 6px 27px 5px 35px;
    align-items: center;
    background-color: white;
    border: 1px solid $color-grey-30;
    border-radius: 9px;
    font-size: 14px;
    color: $color-grey;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: content-box;

    &:focus-visible {
      outline: none;
      border-radius: 0px;
      background-color: #fff;
      border-radius: 9px;
    }
  }
}

.articleButton {
  color: $color-green;
  background-color: $color-white;
  max-height: 56px;
  font-size: 100%;
  font-weight: 800;
  line-height: 56px;
  width: 150px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid $color-green;

  &:hover:enabled {
    color: $color-white;
    background-color: $color-green;
  }

  &.active {
    color: $color-white;
    background-color: $color-green;
  }
}

.editButton {
  color: $color-green;
  background-color: $color-white;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 800;
  width: 50px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid $color-green;
  padding: 0px;
  margin-top: 10px;
}

.editButton:hover {
  color: $color-white;
  background-color: $color-green;
}

.article-form-container {
  width: 80%;
}

.help-content-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 35rem;
  background: $color-white;
  border: 1px solid $color-grey-10;
  border-radius: 10px;
  padding: 1.5rem;

  .leftColumn {
    width: 33%;
    overflow-y: scroll;

    ul {
      list-style: none;
      padding: 0;

      li {
        cursor: pointer;
        padding: 8px;

        &:hover {
          font-weight: bold;
          color: blue;
          text-decoration: underline;
        }
      }

      li.selected {
        color: blue;
        text-decoration: underline;
      }
    }
  }

  .rightColumn {
    width: 66%;
    padding-left: 20px;
    padding-right: 20px;
    max-height: 600px;
    overflow-y: auto;
  }
}
