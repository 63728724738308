@import '../../../../common/common';

.price-decision-tooltip {
  .content {
    padding: 1rem;
    font-size: 0.75rem;
  }

  .header {
    font-size: 0.8rem;
    font-size: inherit;
    font-weight: 700;
    color: $color-brown;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .body {
    display: flex;
    justify-content: space-between;

    .label {
      font-weight: 600;
      color: $color-grey;
    }

    .value {
      color: $color-grey-80;
    }

    .left {
      // width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .icon {
        font-size: 2rem;
        margin: 1rem;

        &.check,
        &.arrow-up {
          color: #72f066;
        }
        &.arrow-down {
          color: $color-warning;
        }
      }

      .value {
        font-size: 1rem;
        width: 6rem;
        text-align: center;
      }
    }

    .divider {
      border: none;
      border-right: 1px solid $color-grey-divider;
      margin: 0;
    }

    .right {
      .list-item {
        margin-bottom: 0.5rem;
      }
      .value {
        display: flex;
        align-items: center;
        height: 1.25rem;

        .icon {
          font-size: 0.75rem;
          margin-left: 0.5rem;

          &.check,
          &.triangle-up {
            color: #72f066;
          }
          &.triangle-down {
            color: $color-warning;
            rotate: 180deg;
          }
        }
      }
    }
  }
}
