@import '../../common/_variables.module.scss';
@import '../../common/_common.scss';

.day-picker {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__checkbox {
    display: none;
  }
  &__label:hover {
    cursor: pointer;
  }
  &__checkbox:checked + &__label {
    color: $color-white;
    background-color: $color-green;
  }
  &__checkbox:focus + label {
    border: 1px solid;
  }

  &__checkbox + &__label {
    @include primary-text;
    display: inline-block;
    border-radius: 50%;
    background: $color-white;
    height: 32px;
    width: 32px;
    margin-right: 3px;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
  }
}

.error {
  color: $color-error;
  font-family: $font-family-main;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

.weekDays-selector input {
  display: none !important;
}

.weekDays-selector input[type='checkbox'] + label {
  display: inline-block;
  border-radius: 100px;
  background: $color-white;
  height: 30px;
  width: 30px;
  margin-right: 3px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
}

.weekDays-selector input[type='checkbox']:checked + label {
  background: $color-green;
  color: $color-white;
}
