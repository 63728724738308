@import '../../../../common/_common.scss';

.form-buttons {
  display: flex;
  justify-content: space-around;
  padding: 3rem 0 1.5rem;

  button {
    @include primary-text;
    @include button-success;
    display: inline-block;
    color: $color-green;
    background-color: $color-white;

    &:hover:enabled {
      color: $color-white;
      background-color: $color-green;
    }
  }

  .left {
    width: 60%;
    padding: 0 3rem;
  }
  .right {
    display: flex;
    width: 35%;
  }

  .back {
    margin-right: 3rem;
  }
  .continue {
    @include button-success;
  }

  :disabled {
    color: $color-white;
    background: $color-light-grey;
    border: 1px solid $color-light-grey;
    cursor: not-allowed;
  }
}
