@import '@/common/common';

.rates-info {
  box-sizing: border-box;
  position: relative;
  background: $color-white;
  height: 100%;
  padding: 1rem;
  border-radius: 20px;

  .toggle-btn {
    @include button-no-style;
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: $color-brown;
    z-index: 1;

    .text-icon {
      width: 1rem;
    }
  }

  .info {
    padding-bottom: 1rem;

    .header {
      @include primary-text;
      color: $color-grey-70;
      font-weight: 500;
      font-size: 1rem;
    }

    .text {
      @include primary-text;
      margin-bottom: 2rem;
    }
  }
}
