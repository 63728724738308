@import '../../../common/_common.scss';

.modal-backdrop {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18%;
  height: 10%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-white;
  z-index: 1050;
}

.modal-content {
  padding: 20px;
  background: $color-green;
  color: $color-white;
  border-radius: 9px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
