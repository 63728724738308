@import '../../../../common/common';

.dashboard-controls {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .controls-container {
    display: flex;
    align-items: center;
    height: 16px;
  }

  .icon-btn {
    padding: 0;
    color: $color-brown;
  }

  .selected-date {
    font-size: 1.125rem;
    color: $color-brown;
    margin-right: 4px;
  }

  .event-icon {
    margin-left: 12px;
  }
}
