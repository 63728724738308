@import '../../common/_variables.module.scss';
@import '../../common/_common.scss';

.toggle-button-switch {
  max-width: 14rem;
  width: 100%;
  min-width: 8rem;

  .content {
    display: flex;
    text-align: center;
    width: 100%;
  }

  .toggle-btn-label {
    width: 100%;
    background-color: $color-grey-30;
    position: relative;
    cursor: pointer;
  }

  .toggle {
    position: absolute;
    width: 50%;
    background-color: $color-white;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  .toggle-btn-label,
  .toggle {
    display: flex;
    align-items: center;
    height: 1.9rem;
    border-radius: 100px;
  }

  .names {
    font-size: 90%;
    font-weight: bolder;
    width: 100%;
    height: 2rem;
    margin-top: 0.5%;
    position: absolute;
    display: flex;
    justify-content: space-around;
    align-items: center;
    user-select: none;
    transition: 0.9s ease all;

    p {
      @include primary-text;
      color: $color-green;
      width: 100%;
    }

    p.on::before {
      content: '';
      display: block;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: $color-green;
      margin-right: 5px;
    }
  }

  .on {
    color: $color-green;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.5s;
  }

  .off {
    color: $color-white !important;
  }

  [type='checkbox'] {
    display: none;
  }

  input[type='checkbox']:not(:checked) + .content .toggle {
    width: 60%;
    transition: 0.5s ease;
    margin-left: 2px;
    height: 1.6rem;
  }

  [type='checkbox']:checked + .content .toggle {
    width: 50%;
    transform: translateX(100%);
    width: 49.5%;
    height: 1.6rem;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
