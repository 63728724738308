@import '../../common/common';

.alerts-container {
  position: absolute;
  z-index: 200;
  padding: 0.5rem;

  .MuiAlert-standard {
    margin-bottom: 0.5rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16);
  }

  a {
    color: darken($color: $color-blue, $amount: 25);
  }
}
