@import '../../common/common';
@import '../../common/variables.module';
@import '../../common/buttons';

.group-price-tool {
  padding: 2rem;
  .group-info {
    font-size: 1rem;
    color: $color-grey-60;
    font-weight: normal;
    margin-left: 30px;
    display: flex;
    align-items: center;
    .selected-group {
      margin-right: 20px;
      .group-name {
        font-weight: 700;
      }
    }
    .status-wrapper {
      display: flex;
      .accepted {
        color: $color-green;
      }
      .not-accepted {
        color: red;
      }
      .group-status {
        display: flex;
        align-items: center;
        margin-left: 5px;
        svg {
          margin-left: 5px;
        }
      }
    }
    .toggle-accept {
      @include button;
      margin-left: 20px;
      width: 60px;
    }
    .accept-button {
      background-color: $color-green;
    }
    .reject-button {
      background-color: red;
    }
  }

  input {
    @include primary-text;
    padding: 0.5rem 0rem 0.5rem 0.75rem;
    width: 7rem;
  }

  .MuiOutlinedInput-root {
    min-width: 70px;
    border-radius: 10px;
  }

  .MuiInputLabel-root {
    @include primary-text;
    top: -5px;
    &.MuiInputLabel-shrink {
      top: 0;
    }
  }

  .MuiTypography-root {
    font-size: 0.8rem;
    max-width: 7rem;
  }

  &__form {
    display: flex;
    flex-direction: column;
    .form-row {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }

    .advanced-fields {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 1rem;
      margin-top: 1rem;
    }

    .submit {
      @include primary-text;
      @include button-success;
      padding: 0.5rem 1rem;
      border-radius: 10px;
      cursor: pointer;
      margin: 0;
    }

    .advanced-toggle {
      background: none;
      border: none;
      cursor: pointer;
    }

    .MuiBox-root {
      display: flex;
      flex-direction: column;
      align-items: center;
      .slider-label {
        display: flex;
        align-items: center;
        .MuiInputBase-root {
          margin-left: 0.5rem;
        }
        .MuiInputAdornment-root {
          margin-left: none;
        }
        .end-adornment {
          display: flex;
          align-items: center;
        }

        input {
          width: 2rem;
        }

        span {
          font-size: 0.8rem;
        }
      }
      .MuiSlider-root {
        color: $color-green;
        max-width: 150px;
      }
    }
  }

  &__tables {
    display: flex;
    flex-direction: column;
    .loading-icon {
      height: 75px;
      width: 75px;
      margin: 50px auto;
      color: $color-green;
    }

    .refetch {
      @include button;
      margin: 0 auto;
      color: white;
      background-color: $color-green;
      width: 220px;
      margin-top: 1rem;
    }
  }

  &__grid {
    .ag-theme-alpine {
      width: auto;
      min-width: fit-content;
    }

    .ag-cell {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.group-list {
  &__item {
    font-size: 1rem;
    cursor: pointer;
    &:hover {
      color: $color-green;
    }
  }
}

.switch-modal {
  cursor: pointer;
  &:hover {
    color: $color-green;
  }
}
