@import '../../common/variables.module';

.availability-table-container {
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid $color-grey-30;
  margin-top: 25px;
  overflow-x: auto;
}

.availability-table {
  width: 100%;
  background-color: white;
  border-radius: 12px;
  border: 1px solid $color-grey-6;
  border-collapse: collapse;

  tr td:first-child,
  tr th:first-child {
    border-right: 1px solid $color-grey-30;
    width: 33%;
    position: sticky;
    left: 0;
    z-index: 1;
    // width: 200px;
    background-color: white;
  }

  tbody {
    tr:hover {
      background-color: rgba(33, 150, 243, 0.1);
    }
  }

  tr {
    border-bottom: 1px solid $color-grey-20;
  }

  tr:nth-child(even) {
    background-color: #fcfcfc;
  }

  tr:nth-child(even) td:first-child,
  tr:nth-child(even) th:first-child {
    background-color: #fcfcfc;
  }

  td {
    padding: 0.5rem;
    font-size: 14px;
  }

  .room-cell-1 {
    padding-left: 2rem;
    white-space: nowrap;
  }

  .row-0 {
    font-weight: 700;
  }

  .rt_remaining-cell {
    text-align: center;
  }

  // thead th {
  //   font-size: 14px;
  //   background-color: $color-grey-6 !important;
  //   z-index: 2;
  // }

  th {
    padding: 1rem;
    font-weight: 700;
    font-size: 14px;
    // background-color: $color-grey-6;
    background-color: #f4f4f4 !important; /* Ensure the first header cell has the correct background color */

    z-index: 2;
    position: sticky; /* Ensure the header cells are sticky */
    top: 0;
  }

  tr th:first-child {
    z-index: 3; /* Ensure it is above other header cells */
    position: sticky; /* Ensure the first header cell is sticky */
    left: 0; /* Stick to the left */
  }

  .expand-button {
    background: none;
    border: none;
  }
}
