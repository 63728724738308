@import '../../../../common/common';

.competitor-table {
  box-sizing: border-box;
  height: 100%;
  padding: 2rem;

  .header {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    margin-bottom: 2rem;

    .season-select-container,
    .toggle-switch {
      flex: 1;
    }
  }

  .table {
    height: 80%;

    .customHeader {
      .sortDownArrow,
      .sortUprrow {
        margin-left: 10px;
      }
    }

    .ag-root-wrapper {
      border-radius: 12px;
      font-family: $font-family-main;
      text-align: center;
      border: 1px solid $color-grey-10;
    }

    .ag-header {
      background-color: $color-grey-47;
      min-height: 52px !important;
    }

    .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
      border-color: none;
      border: none;
    }

    .ag-header-row {
      color: $color-white;
      font-weight: $font-weight--regular;
      font-size: 15px;
      white-space: break-spaces;
      height: 100% !important;
      border: 1px solid $color-grey-10;
    }

    .ag-row-odd {
      background-color: $color-white;
    }

    .ag-row {
      border-bottom: 0.5px solid $color-grey-10 !important;
    }

    .ag-row-selected,
    .ag-pinned-left-cols-container .ag-row-hover,
    .ag-center-cols-clipper .ag-row-hover {
      background-color: $color-grey-6;
    }

    .ag-cell {
      display: inline-flex;
      align-items: center;
      height: 100%;
      line-height: normal;
    }

    .ag-floating-bottom {
      border-top: 1px solid $color-grey-10 !important;
    }

    .numeric-editor {
      margin: 0 0.75rem;
      width: 100%;
      background: rgba(255, 212, 100, 0.3);
      border-radius: 10px;
      border: none;
      padding: 0.25rem 0;
      text-align: center;
    }
  }
}
