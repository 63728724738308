@import '../../../../common/common';

.rate-shop-modal {
  .modal__body {
    padding-left: 10px;

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    label {
      color: $color-grey-main;
    }

    .common-dropdown-container {
      width: 14rem;

      .MuiInputBase-root,
      .MuiMenuItem-root {
        width: initial;
      }
    }

    .MuiFormGroup-root {
      flex-direction: row;
      width: 14rem;

      .MuiButtonBase-root {
        color: $color-grey-main;
        &.Mui-checked {
          color: $color-green;
        }
      }
    }
  }

  .modal__confirmation-btns {
    &--cancel {
      color: $color-warning;
    }
    &--confirm {
      border-color: $color-green;
      background-color: $color-green;
    }
  }
}
