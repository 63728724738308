@import '../../../common/_variables.module.scss';
.askrev {
  border: 1px solid #babfc7;
  border-radius: 12px;
  padding: 2rem 7rem;
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 15px;

  &__left {
    padding-right: 60px;
    text-align: center;
  }

  &__icon {
    border: 3px solid $color-green;
    padding: 10px 20px;
    border-radius: 5px;
  }

  &__title {
    color: $color-green;
    font-size: 18px;
    padding-top: 12px;
  }

  &__close {
    position: absolute;
    right: 15px;
    top: 10px;
  }

  &__search-list {
    margin: 0;
    list-style-type: none;
  }

  &__search-list-item {
    border-radius: 9px;
    padding: 0 12px;
    margin-bottom: 10px;
    margin-right: 10px;
    font-size: 14px;
  }
  .MuiFormControlLabel-label {
    color: $color-grey-50;
  }
  .MuiRadio-colorSuccess {
    padding: 3px !important;
    display: none;
  }

  .MuiRadio-root.Mui-checked + span {
    color: $color-green;
  }
}
