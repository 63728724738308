@import '../../../../common/common';

.competitor-info {
  position: relative;
  background: $color-white;
  border-radius: 20px;
  box-sizing: border-box;
  overflow: hidden;

  .toggle-btn {
    @include button-no-style;
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: $color-brown;
    z-index: 1;
  }

  .info {
    padding: 1rem;

    .header {
      @include primary-text;
      color: $color-grey-70;
      font-weight: 500;
      font-size: 1rem;
    }

    .text {
      @include primary-text;

      li {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }

  .chart {
    padding: 0.5rem;

    .select-competitor {
      width: 45%;
      display: flex !important;
      margin: auto !important;
    }
    .MuiOutlinedInput-root,
    .MuiOutlinedInput-notchedOutline {
      @include primary-text;
      border: none;
    }
  }
}
