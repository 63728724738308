@import '../../common/common';

.admin-container {
  box-sizing: border-box;
  margin-top: 80px;
  padding: 20px;
  background-color: #ffffff;
  font-family: $font-family-main;
  color: $color-grey-60;

  label {
    margin-right: 10px;
  }

  .admin-field {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .MuiInputBase-root {
    height: 30px;
  }

  .support-message-input {
    height: 30px;
    width: 400px;
    font-family: $font-family-main;
    font-size: 14px;
    border: 1px solid $color-grey-60;
    border-radius: 5px;
  }

  .save {
    @include primary-text;
    @include button-success;
    margin: 0px 0px 0px 5px;
    width: 60px;
  }

  .clear {
    @include button-warning;
    width: 120px;
    margin: 0px 0px 0px 5px;
  }

  .migrations {
    display: flex;
    flex-direction: column;
    color: $color-warning;
    &-button {
      @include button;
      width: 140px;
      margin: 0px 0px 0px 10px;
      background-color: $color-green;
      margin-top: 20px;
      span {
        margin-right: 7px;
      }
    }

    p {
      margin: 10px 0px !important;
    }

    ul {
      margin: 0px;
    }
  }

  .links {
    margin-top: 2rem;

    button {
      @include button;
      display: inline-block;
      width: auto;
      color: $color-green;
      background: #fff;
      margin-right: 1rem;
    }
  }
}
