@import '../../../../common/common';

.autopilot-info {
  box-sizing: border-box;
  background: $color-white;
  height: 100%;
  padding: 1rem;
  border-radius: 20px;

  .header {
    @include primary-text;
    color: $color-grey-70;
    font-weight: 500;
    font-size: 1rem;
  }

  .text {
    @include primary-text;
    margin-bottom: 2rem;
  }
}
