@import '../../common/_variables.module.scss';

.chat-window {
  position: fixed;
  bottom: 10px;
  right: 130px;
  background: white;
  display: flex;
  flex-direction: column;
  width: 400px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.4),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  overflow: hidden;
  z-index: 1000;

  header {
    background: $color-green;
    color: white;
    font-family: $font-family-main;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 0.75rem;
    border-bottom: 1px solid white;
    .window-controls {
      button {
        color: white;
        background: none;
        border: none;
        cursor: pointer;
        .control-icon {
          width: 1.5rem;
          height: 1.5rem;
        }
        .close {
          width: 1.3rem;
          height: 1.3rem;
        }
      }
    }
  }

  .header-button {
    cursor: pointer;
    border: none;
  }

  .chat-messages {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
    padding-bottom: 0;
    position: relative;

    .message-wrapper {
      display: flex;
      align-items: flex-start;
      gap: 1rem;
      .message {
        padding: 1rem;
        border-radius: 1rem;
        font-family: $font-family-main;
        line-height: 1.25rem;
        max-width: 75%;
        word-wrap: break-word;
      }
    }

    .wrapper-user {
      justify-content: flex-end;
      .user-msg {
        background: $color-green;
        color: white;
      }
    }

    .wrapper-assistant {
      justify-content: flex-start;
      .assistant-msg {
        background: $color-grey-10;
      }
    }

    .connecting-msg {
      position: absolute;
      bottom: 1rem;
      left: 1rem;
      padding: 0.5rem;
      font-size: 0.875rem;
      color: $color-grey-60;
    }
  }

  .chat-messages > * + * {
    margin-top: 1rem;
  }

  .chat-input {
    display: flex;
    position: relative;
    padding: 1rem;
    border-top: 1px solid #e0e0e0;

    textarea {
      flex: 1;
      padding: 0.5rem;
      border: 1px solid #e0e0e0;
      border-radius: 5px;
      padding: 0.5rem;
      padding-right: 4rem;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
      height: 70px;
      resize: none;
      font-family: $font-family-main;
      font-size: 16px;
    }

    button {
      color: $color-green;
      position: absolute;
      top: 1.2rem;
      right: 1rem;
      background: none;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      .send-button {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
  }

  .typing-indicator {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    .typing-dot {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background: $color-grey-70;
      animation: typing 1s infinite;
    }

    .typing-dot:nth-child(1) {
      animation-delay: 0s;
    }

    .typing-dot:nth-child(2) {
      animation-delay: 0.2s;
    }

    .typing-dot:nth-child(3) {
      animation-delay: 0.4s;
    }

    @keyframes typing {
      0%,
      20%,
      100% {
        background-color: darkgreen;
      }
      40% {
        background-color: $color-green;
      }
    }
  }
}

.chat-full {
  height: 600px;
}
