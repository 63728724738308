@import '../../../../common/common';

.comp-rate-tooltip {
  padding: 1rem;
  font-size: 0.8rem;
  color: $color-grey-80;

  .row {
    margin-bottom: 0.25rem;
  }

  .label {
    font-weight: 600;
    color: $color-grey;
    margin-right: 0.25rem;

    &.sub {
      font-weight: normal;
    }
  }
}
