@import '@/common/common';

.rates {
  &__chart {
    &-header {
      &-text {
        @include primary-text;
        display: flex;
        justify-content: center;
        align-items: center;

        .MuiFormControl-root {
          margin: 0 !important;
        }

        button {
          position: relative !important;
          top: 0 !important;
          right: 0 !important;
          color: $color-grey-main;
          padding: 0 !important;
        }

        .MuiSvgIcon-root {
          height: 0.8em !important;
          width: 0.8em !important;
        }

        .MuiInputBase-root {
          input {
            @include primary-text;
            width: 75px;
            padding: 0;
            border-bottom: 1px solid;
            margin-left: 5px;
          }

          fieldset {
            border: none;
          }
        }
      }
    }
  }
}

.rule__right {
  .tooltip__container-text {
    display: flex;
    justify-content: space-between;
  }
}
