@import '../../../../common/common';

.overlapping-modal {
  &__message {
    @include primary-text;
  }

  &__container {
    display: flex;
  }

  &__emptytext {
    @include primary-text;
    width: 40%;
  }

  &__listitem {
    .MuiMenuItem-root {
      @include primary-text;
    }
  }

  &__primarytext {
    @include primary-text;

    .MuiInputBase-root,
    .MuiMenuItem-root {
      color: $color-grey-main !important;
      font-family: $font-family-main !important;
      font-weight: $font-weight--regular !important;
      font-size: 14px !important;
      line-height: 16px !important;
    }

    .MuiSelect-select,
    .MuiMenuItem-root {
      @include primary-text;
    }

    .MuiSelect-select {
      padding: 7px 32px 7px 12px;
      min-height: 0 !important;
      border-radius: 10px;
    }

    .MuiOutlinedInput-root {
      border-radius: 10px;
      margin-right: 5px;
    }
  }

  &__disabledSelect {
    .MuiSvgIcon-root {
      display: none;
    }
  }

  &__text {
    @include primary-text;
    width: 60%;

    &__priority {
      display: flex;
      justify-content: center;
      margin-bottom: 5px;
    }

    &__container {
      display: flex;
      justify-content: space-around;
      padding: 5px 0px;
      border-top: 1px solid $color-grey-10;
    }
  }

  &__header {
    justify-content: center;
  }

  &__subheader {
    display: flex;
    justify-content: space-around;
  }
}

.overlapping-modal__primarytext {
  color: $color-grey-main !important;
  font-family: $font-family-main !important;
  font-weight: $font-weight--regular !important;
  font-size: 14px !important;
  line-height: 16px !important;
}
