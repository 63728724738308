@import '../../common/_variables.module.scss';

.navContainer {
  height: 100%;
  .navItems {
    padding: 10px 11px;
    list-style: none;
    text-align: center;
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .navButton,
  .disabledNavButton {
    &__icon {
      background-color: darken($color-light-grey, 1);
      height: 40px;
      display: flex;
      margin: 0 8px;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
    }

    &__link {
      text-decoration: none;
    }

    &__text {
      font-size: 12px;
      color: $color-white;
      padding: 5px 0px;
    }

    &__active-link {
      .navButton__icon {
        background-color: $color-green;
      }

      .navButton__text {
        color: $color-green;
      }
    }
  }

  .navButton {
    &:hover,
    &:focus-within {
      .navButton__icon {
        background-color: $color-green;
      }

      .navButton__text {
        color: $color-green !important;
      }
    }
  }
}
