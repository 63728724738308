@import '../../../common/_variables.module.scss';
@import '../../../common/_common.scss';
.chart {
  &__container {
    margin: 75px 10px 25px 0px;
    background: $color-white;
    min-height: 35rem;
    .MuiSelect-select {
      padding: 7px 32px 7px 12px !important;
    }
    .MuiInputBase-root {
      border-radius: 10px !important;
    }

    .tooltip {
      width: 200px;

      th {
        font-weight: $font-weight--regular;
        font-size: 15px;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid $color-grey-30;
      }

      .series-name {
        display: flex;
        align-items: center;
        padding: 10px 0px 0px 10px;
        @include primary-text;
        color: $color-grey-50;
      }
      .circle {
        height: 10px;
        width: 10px;
        margin-right: 10px;
        border-radius: 50%;
      }
      .series-value {
        font-weight: $font-weight--bold;
        font-size: 14px;
        padding: 10px 0px 0px 10px;
      }
    }

    .highcharts-yaxis-grid .highcharts-grid-line {
      stroke-width: 0.6px;
      stroke: rgba(72, 72, 72, 0.1);
    }
    /* Highchart css*/
    .highcharts-axis-labels .weekend {
      fill: $color-orange;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .chart__dropdown-graphType {
      .MuiSelect-select {
        width: 162px !important;
      }
    }
  }

  &__content {
    border-radius: 10px;
    padding: 30px;
    margin: 20px 15px;
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  &__backBtn {
    &-icon {
      @include primary-text;
    }
    &-text {
      @include primary-text;
      margin-left: 10px;
    }
  }
}
