@import '../../common/_variables.module.scss';
@import '../../common/_common.scss';

.date-container {
  button {
    @include primary-text;
  }

  .MuiFormControl-root {
    margin-top: 0;
    margin-bottom: 0;
  }

  .MuiOutlinedInput-root {
    width: 200px;
  }

  .MuiInputLabel-root {
    @include primary-text;
    top: -5px;
    &.MuiInputLabel-shrink {
      top: 0;
    }
  }
}
