@import '../../../common/_common.scss';

.form-row-inline {
  display: flex;
  margin-right: 10px;
  flex-wrap: nowrap;
  gap: 10px;
  height: 30px;
}

.form-group {
  flex: 1;
  min-width: 100px;
}

.form-group label {
  margin-right: 5px;
  white-space: nowrap;
}

.form-control {
  background-color: $color-white;
  border: 1px solid $color-green;
  padding: 3px;
  border-radius: 10px;
  outline: none;
}

.form-control:hover {
  border: 2px solid $color-green;
  padding: 2px;
}

.form-control:focus {
  border: 2px solid #000000af;
  padding: 2px;
}

.submit-button {
  color: $color-green;
  background-color: $color-white;
  max-height: 56px;
  font-size: 100%;
  font-weight: 800;
  line-height: 56px;
  width: 150px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid $color-green;
  margin-top: 3px;

  &:hover:enabled {
    color: $color-white;
    background-color: $color-green;
  }

  &.active {
    color: $color-white;
    background-color: $color-green;
  }
}
