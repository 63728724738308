@import '@/common/common';

.rates-form {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  padding-bottom: 5rem;

  .season-name {
    width: 50%;
  }

  .row {
    padding: 1rem 0 0.5rem;
  }

  .header {
    @include primary-text;
    padding: 0.75rem;
    font-weight: 600;
    margin: 0;
    margin-bottom: 1rem;
    background: rgba(196, 196, 196, 0.2);
  }

  .body {
    display: flex;
    justify-content: space-between;
    margin-left: 0.75rem;
    margin-right: 15%;

    .field-box {
      display: flex;
      align-items: center;
      width: 15rem;
    }

    .label {
      @include primary-text;
      margin-right: 1rem;
      width: 4rem;
    }

    .MuiOutlinedInput-root {
      width: 6rem;
      height: 2rem;
      border-radius: 10px;

      &.offset {
        width: 3.5rem;
        margin-right: 2.5rem;
      }

      &.number-input {
        width: 7rem;
      }

      &.rate-proxy {
        width: auto;
      }

      .MuiInputAdornment-root {
        margin-right: 0px;
      }

      .MuiTypography-root {
        @include primary-text;
      }
    }
  }
}
