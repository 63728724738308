@import '../../../../common/common';

.video-uploader-container {
  .video-uploader-content {
    background-color: #fff;
    margin-top: 2rem;
    padding: 2rem;
  }

  .row {
    margin-bottom: 2rem;
  }

  button,
  .upload-button {
    @include primary-text;
    @include button-success;
    display: inline-block;
    color: $color-green;
    background-color: $color-white;

    &:hover:enabled {
      color: $color-white;
      background-color: $color-green;
    }
  }
  .upload-button {
    display: inline-flex;
    width: 10rem;
    box-shadow: none;

    &:hover {
      color: $color-white;
      background-color: $color-green;
      box-shadow: none;
    }
  }
  .submit-button {
    @include button-success;
    display: inline-block;
    margin-left: 1rem;
  }

  :disabled {
    color: $color-white;
    background: $color-light-grey;
    border: 1px solid $color-light-grey;
    cursor: not-allowed;
  }

  .file-selection {
    margin-left: 0.5rem;
    font-size: 0.9rem;
  }

  input {
    @include primary-text;
    padding: 0.4rem 0.75rem;
    width: 22rem;
  }

  .MuiOutlinedInput-root {
    border-radius: 10px;
  }
  .MuiInputLabel-root {
    @include primary-text;
    top: -5px;
    &.MuiInputLabel-shrink {
      top: 0;
    }
  }

  .ag-theme-alpine {
    height: 300px;
    max-width: 30rem;
  }

  .status-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      font-size: 1rem;
      margin: 0 0 2px 2px;
      color: $color-warning;
    }
  }
}
