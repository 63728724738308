@import '../../../../common/common';

.carousel {
  margin: 8px 0;
}

.alert-card {
  display: flex;
  flex-direction: column;
  height: 100%;

  .alert-content.MuiPaper-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
    height: 100%;
  }

  button {
    text-transform: none;
  }

  .circle-btn {
    flex-direction: column;
    width: 100px;
    height: 100px;
    border-radius: 50%;

    .nights-count {
      font-size: 1.75rem;
      line-height: 1.75rem;
    }
  }

  .message {
    font-size: 0.75rem;
    margin: 0 32px;
  }

  .review-btn {
    width: 100%;
    font-weight: 700;
    box-shadow: none;
    border-radius: 0 0 10px 10px;
  }

  &.warning {
    .circle-btn {
      color: $color-warning;
      border: 2px solid $color-warning;
      background: rgba($color-warning, 0.07);
      &:hover {
        border: 2px solid darken($color-warning, 20);
        background: rgba($color-warning, 0.1);
      }
    }
    .review-btn {
      background: #fc5f5f;
      &:hover {
        background: darken(#fc5f5f, 10);
      }
    }
  }

  &.insight {
    .circle-btn {
      color: $color-green;
      border: 2px solid $color-green;
      background: rgba($color-green, 0.07);
      &:hover {
        border: 2px solid darken($color-green, 20);
        background: rgba($color-green, 0.1);
      }
    }
    .review-btn {
      background: #1eac11;
      &:hover {
        background: darken(#1eac11, 10);
      }
    }
  }
}

.chart-content {
  height: 100%;
  box-sizing: border-box;

  .rev-total-container {
    position: absolute;
    display: flex;
    margin: 16px;

    .arrow {
      font-size: 1.25rem;
      align-self: center;
      margin-right: 12px;

      &.up {
        color: #72f066;
      }
      &.down {
        color: $color-warning;
        rotate: 180deg;
      }
    }

    .rev-total {
      display: flex;
      flex-direction: column;
      color: $color-grey-80;

      .amount {
        font-size: 1.25rem;
      }
      .text {
        font-size: 0.75rem;
      }
    }
  }
}
