@import '../../common/common';

.two-pane-layout {
  display: flex;

  .left {
    width: 60%;
    background: $color-white;
    border: 1px solid $color-grey-10;
    border-radius: 10px 0px 0px 10px;
    box-sizing: border-box;
  }

  .right {
    box-sizing: border-box;
    width: 40%;
    background: linear-gradient(
      90deg,
      $color-grey-5 34.9%,
      $color-grey-35 100%
    );
    border-radius: 0px 20px 20px 0px;
    padding: 3rem;
  }
}
