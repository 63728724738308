@import '../../../../common/common';

.autopilot-form {
  margin: 32px 80px;

  .row {
    display: flex;
    justify-content: space-between;
  }

  .season-name {
    width: 50%;
  }

  .ap-error {
    color: $color-error;
    font-size: 0.75rem;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .header {
    @include primary-text;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 500;
    color: $color-grey-70;
    margin-bottom: 15px;
    background: rgba(196, 196, 196, 0.2);

    :first-child {
      padding-left: 10px;
    }
    :last-child {
      width: 20%;
    }
  }

  .top-labels {
    display: flex;
    width: 60%;
    text-align: center;
  }

  .label {
    @include primary-text;
    display: inline-block;
    width: 80px;
    text-align: center;

    &.large {
      font-size: 16px;
      width: 48px;
      text-align: left;
      line-height: 30px;
      margin: 0 16px 0 10px;
    }
  }

  .main-section {
    display: flex;
    justify-content: space-between;

    .row {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .priority-section {
    width: 60%;
  }

  .priority-fields {
    display: flex;
    justify-content: space-between;
    width: 100%;
    span {
      width: 120px;
    }
  }

  .divider hr {
    border: none;
    border-right: 0.5px solid $color-grey-50;
    height: 100%;
    margin: 0;
    filter: blur(2px);
  }

  .autopilot-section {
    width: 20%;
  }

  .autopilot-select-container .MuiFormControl-root {
    width: 80px;
    margin-right: 10px;
  }

  .Mui-disabled {
    cursor: not-allowed;
    .MuiOutlinedInput-notchedOutline {
      background: $color-grey-5;
    }
  }
}
