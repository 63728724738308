@import '../../../../common/_common.scss';
@import '../../../../common/_variables.module.scss';

.rule-tabs {
  display: flex;
  justify-content: space-around;
  margin: 2.25rem 0;

  a {
    button {
      border: none;
      background: none;
      cursor: pointer;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;

        color: $color-green;
        border: 2px solid $color-green;
        border-radius: 50%;
        box-sizing: border-box;
        height: 70px;
        width: 70px;
      }

      .label {
        @include primary-text;
        color: $color-green;
        padding-top: 0.75rem;
      }

      &:disabled {
        cursor: not-allowed;

        .icon {
          background: $color-light-grey;
          border: none;
          color: $color-white;
        }

        .label {
          color: $color-dark-grey;
        }
      }
    }

    &.active {
      .icon {
        background: $color-green;
        color: $color-white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }

    &:hover,
    &:focus-within {
      button:enabled .icon {
        background: $color-green;
        color: $color-white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }
}
