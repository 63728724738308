@import './buttons';
@import './variables.module';

@mixin primary-text {
  color: $color-grey-main;
  font-family: $font-family-main;
  font-weight: $font-weight--regular;
  font-size: 14px;
  line-height: 16px;
}

@mixin scroll-shadows {
  max-height: 200px;
  overflow: auto;

  background:
    // Shadow Cover TOP
    linear-gradient(white 30%, rgba(255, 255, 255, 0)) center top,
    //  Shadow Cover BOTTOM
    linear-gradient(rgba(255, 255, 255, 0), white 70%) center bottom,
    // Shadow TOP
    radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      center top,
    // Shadow BOTTOM
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      center bottom;

  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}

.common-dropdown-container {
  .MuiInputBase-root,
  .MuiMenuItem-root {
    @include primary-text;
    width: 100%;
  }

  .MuiSelect-select,
  .MuiMenuItem-root {
    @include primary-text;
  }

  .MuiInputLabel-root {
    @include primary-text;
    .MuiInputLabel-asterisk {
      color: $color-brown;
      height: 15px;
      display: block;
    }
  }

  .MuiFormLabel-root {
    position: relative;
    top: 8px;
  }

  .MuiSelect-select {
    padding: 7px 32px 7px 12px;
    min-height: 0 !important;
    border-radius: 10px;
  }

  .MuiOutlinedInput-root {
    border-radius: 10px;
    margin-right: 5px;
  }
}
