@import '../../common/_variables.module.scss';

.help-button {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 95px;
  height: 45px;
  background-color: #55c652;
  color: #244b23;
  font-weight: $font-weight--bold;
  font-size: 16px;
  margin: 10px 20px;
  border-radius: 999rem;
  border: none;
  letter-spacing: 0.6px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;

  .help-button__text {
    margin-left: 5px;
  }
}

@media only screen and (max-width: 768px) {
  .help-button {
    bottom: 31px;
    left: 40%;
    height: 27px;
    width: 44px;
    font-size: 13px;
  }
}
