@import '../../../../common/common';

.overview {
  position: relative;
  box-sizing: border-box;
  padding: 8px;
  min-width: $overview-panel-min-width;
  z-index: 25;

  .name {
    color: $color-brown;
    margin: 8px;
    font-size: 1.5rem;
    font-weight: 400;
  }

  .text {
    display: inline-block;
    color: $color-grey-80;
    font-size: 0.75rem;
    line-height: 1rem;
    margin: 8px;
    margin-top: 0;

    &.warning {
      color: #df1c1c;
    }
  }

  .details {
    position: absolute;
    z-index: 30;
    background: $color-white;
  }

  .MuiButtonBase-root.expand-btn {
    padding: 0;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &.expanded {
      transform: rotate(180deg);
    }
  }

  .rec-levels {
    width: 100%;
    height: 56px;
    margin: 8px 0;
    justify-content: center;

    .priority-btn {
      flex-direction: column;
      width: 84px;

      border: none;
      border-right: 1px solid $color-grey-divider;
      &:last-child {
        border-right: none;
      }

      .count {
        font-size: 1.5rem;
        height: 40px;
        line-height: 2rem;

        &.high {
          color: $color-warning;
        }
        &.med {
          color: $color-medium-priority;
        }
        &.low {
          color: $color-green;
        }
      }

      .priority {
        font-size: 0.625rem;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .name {
    display: none;
  }
  .overview {
    z-index: 0;
    .hide-priorities {
      visibility: hidden;
    }
    .text {
      line-height: 1.2rem !important;
    }
    .details {
      width: 100% !important;
      top: 30px;
    }
  }
  .rec-levels {
    justify-content: space-around !important;
  }
  .mobile-view-warning {
    margin-left: 10%;
  }
  .mobile-collapse-btn {
    z-index: 35;
    // pointer-events: none;
  }
}
