@import '../../../../common/_common.scss';
@import '../../../../common/_variables.module.scss';

.trend-widget {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .tooltip {
    width: 200px;

    th {
      font-weight: $font-weight--regular;
      font-size: 15px;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid $color-grey-30;
    }

    .series-name {
      display: flex;
      align-items: center;
      padding: 10px 0px 0px 10px;
      @include primary-text;
      color: $color-grey-50;
    }
    .circle {
      height: 10px;
      width: 10px;
      margin-right: 10px;
      border-radius: 50%;
    }
    .series-value {
      font-weight: $font-weight--bold;
      font-size: 14px;
      padding: 10px 0px 0px 10px;
    }
  }
}
