@import '../../common/common';

.home {
  width: 100%;
  height: 100%;
  padding: 1.5rem 2rem 1.5rem 1rem;
  box-sizing: border-box;

  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 1rem;
}
.details-pane {
  display: grid;
  gap: 16px;

  .dashboard-controls {
    grid-column: span 12;
  }
  .rec-rate-widget {
    grid-column: span 12;
  }

  .otb-widget {
    grid-column: span 12;
  }
  .occupancy-widget {
    grid-column: span 12;
  }
  .trend-widget {
    grid-column: span 12;
  }
  .inventory-rate-level-widget {
    grid-column: span 12;
  }
}
h3 {
  display: inline-block;
  margin: 0;
  font-size: 1.25rem;
  font-weight: 400;
  color: $color-grey-60;
}

@media only screen and (max-width: 768px) {
  .home {
    width: 100%;
    height: 94%;
    padding: 0;
    padding-bottom: 5px;
    box-sizing: border-box;
    grid-template-columns: 1fr;
  }
  .details-pane {
    display: none;
  }
  .mui-card-content {
    overflow: auto; /* or overflow: visible; depending on your design preference */
  }
}

@media only screen and (min-width: 769px) {
  .home {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-width: 1096px;
    padding: 1.5rem 2rem 1.5rem 1rem;
    box-sizing: border-box;
  }
  .details-pane {
    display: grid;
    grid-template: 2fr 3fr 6fr / repeat(12, 1fr);
    gap: 16px;

    .dashboard-controls {
      grid-column: span 12;
    }

    .rec-rate-widget {
      grid-column: span 5;
    }

    .otb-widget {
      grid-column: span 4;
    }
    .occupancy-widget {
      grid-column: span 3;
    }
    .trend-widget {
      grid-column: span 7;
    }
    .inventory-rate-level-widget {
      grid-column: span 5;
    }
  }
}
