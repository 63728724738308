@import '../../../../common/common';

.season-form {
  position: relative;
  padding: 2rem;
  @include primary-text;

  .row {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0 1.5rem 0.25rem;
  }

  .item {
    width: 50%;
  }

  input {
    @include primary-text;
    padding: 0.5rem 0.75rem;
    width: 25rem;
  }

  .MuiOutlinedInput-root {
    border-radius: 10px;
  }
  .MuiInputLabel-root {
    @include primary-text;
    top: -5px;
    &.MuiInputLabel-shrink {
      top: 0;
    }
  }

  .highlight-myview-checkbox {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $color-black-23;
    border-radius: 10px;
    padding: 0.5rem 0.75rem;
    width: 200px;
    cursor: pointer;

    input {
      box-sizing: border-box;
      accent-color: $color-green;
      width: initial;
      cursor: pointer;
    }
  }

  .priority {
    display: flex;
    width: 200px;

    label {
      display: flex;
      align-items: center;
      width: 40%;
      border: 1px solid $color-black-23;
      border-right: none;
      border-radius: 10px 0px 0px 10px;
      padding-left: 0.75rem;
    }
    .MuiFormLabel-root {
      display: none;
    }

    .MuiFormControl-root {
      width: 60%;
    }

    .MuiInputBase-root {
      border-radius: 0px 10px 10px 0px;
    }

    .MuiSelect-select {
      @include primary-text;
      display: flex;
      align-items: center;
      padding: 0.5rem 0.75rem;
    }
  }

  .updated-by {
    position: absolute;
    right: 2rem;
    bottom: 1rem;
    .footnote {
      color: $color-mandatory-mark;
    }
  }
}
