@import '../../../../common/common';

.otb-widget {
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    position: relative;

    .no-data {
      align-self: center;
      color: $color-grey-60;
    }

    .chart-container {
      z-index: 10;
    }

    .total {
      position: absolute;
      left: calc((100% - 70px) / 2);
      transform: translateX(-50%);
      text-align: center;
      color: $color-grey-60;

      h1 {
        margin: 0;
        font-size: 2rem;
        font-weight: 700;
        height: 40px;
      }
    }
  }
}
