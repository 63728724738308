@import '../../../../common/common';

.rec-rate-widget .content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 32px;
  height: 100%;

  .row {
    display: flex;
    justify-content: space-between;
  }
  .priority-icon {
    margin-right: 8px;
  }
  .section {
    display: flex;
  }
  .rate-override-field {
    display: flex;

    .icon-btn {
      padding: 0;
      height: 24px;
      svg {
        font-size: 1.25rem;
      }
    }
    .override-input {
      font-size: 1.25rem;
      width: 72px;
      text-align: right;
      background: rgba($color-light-yellow, 0.3);
      border-radius: 5px;
      border: none;
      color: $color-grey-80;
    }
  }
  .rate-difference {
    font-size: 1.25rem;
    margin-left: 4px;
  }

  .field {
    .label {
      display: flex;
      font-size: 0.75rem;
      color: #7f8ea5;
      margin: 0;
      margin-bottom: 4px;
    }
    span {
      font-size: 1.25rem;
      color: $color-grey-80;
    }

    .liveCell {
      display: inline-block;
      position: relative;
    }
    .liveCell__star {
      color: $color-green;
      position: absolute;
      right: -4px;
      bottom: 4px;
    }
    .liveCell__value {
      margin-right: 4px;
    }
  }
  .arrow {
    margin-left: 8px;
    &.up {
      color: #72f066;
    }
    &.down {
      color: $color-warning;
      rotate: 180deg;
    }
  }
  .divider {
    border: none;
    border-right: 1px solid $color-grey-divider;
    height: 100%;
    margin: 0;
  }
}
