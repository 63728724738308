#root {
  display: flex;
  height: 100vh;
  align-items: center;
  flex-direction: column;
}
.App {
  width: 97%;
  margin: 1rem auto;
  display: flex;
  border: 1px solid;
  border-color: rgba(72, 72, 72, 0.1);
  border-radius: 20px;
  min-height: 855px;
  height: calc(100vh - 40px);
}

.fixed-size {
  margin-top: 1rem;
  height: 855px;
  margin-right: auto;
}

.left-container {
  background: rgba(90, 87, 96, 0.8);
  border-radius: 20px 0 0 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.right-container {
  border-radius: 0 20px 20px 0;
  padding: 1.5rem 2rem 1.5rem 1rem;
  position: relative;
  width: calc(100% - 60px);
  min-height: 94%;
  overflow-y: auto;
}

.right-container.user {
  background: linear-gradient(180deg, #fdfdfd 12.5%, #e3e5e5 100%);
}

.alerts-container {
  width: calc(100% - 60px);
}

@media only screen and (max-width: 768px) {
  .App {
    height: 100%;
    width: fit-content !important;
    min-height: calc(100vh - 40px);
  }

  .right-container {
    border-radius: 20px 20px 20px 20px;
    padding: 1.5rem 1rem;
    width: 100%;
  }

  .left-container {
    display: none;
  }
}
