@import '../../common/_variables.module.scss';
.autoSearch {
  position: relative;

  &__search-icon {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  &__close-icon {
    position: absolute;
    right: 12px;
    top: 11px;
    cursor: pointer;
  }

  input {
    height: 25px;
    width: 20rem;
    padding: 6px 27px 5px 35px;
    align-items: center;
    background-color: rgba(72, 72, 72, 0.06);
    border: 1px solid $color-grey-6;
    border-radius: 9px;
    font-size: 14px;
    color: $color-grey;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: content-box;
    &:focus-visible {
      outline: none;
      border-radius: 0px;
      background-color: #fff;
      border-radius: 9px;
    }
  }

  &__list {
    list-style: none;
    padding: 12px 0;
    margin: 0px;
    background: #fff;
    width: 99.5%;
    position: absolute;
    border-radius: 9px;
    border: 1px solid #ddd;
    border-top: none;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16);
    margin-top: 3px;
    z-index: 2;
  }

  &__list-item {
    font-size: 14px;
    padding: 8px 10px;

    a {
      text-decoration: none;
      color: $color-grey;
      line-height: 20px;
      display: flex;
      align-items: center;
    }

    &:hover {
      background-color: $color-grey-6;
    }
  }
  .suggestion-active {
    background-color: $color-grey-6;
  }
}

@media screen and (max-width: 400px) {
  .autoSearch input {
    height: 25px;
    max-width: 9rem;
    font-size: 12px;
  }
}
@media screen and (min-width: 401px) and (max-width: 560px) {
  .autoSearch input {
    max-width: 11.5rem;
    font-size: 12px;
  }
}
@media screen and (min-width: 561px) and (max-width: 768px) {
  .autoSearch input {
    max-width: 20rem;
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .autoSearch__list-item {
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
