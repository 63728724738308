@import '../../common/common';

.modal {
  position: relative;

  &__overlay {
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: left;
    align-items: left;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity 0.2s, visibility 0.2s;
    background-color: rgba($color-black, 0.25);
  }

  &__container {
    max-height: 72vh;
    max-width: 22.5rem;
    width: 90%;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 110;
    background-color: #fff;
    text-align: left;
    height: auto;
    padding: 1.6875rem;
    transform: translate(-50%, -50%);
    opacity: 1;
    transition: opacity 0.2s, visibility 0.2s;
    border-radius: 10px;
    border: 1px solid $color-border;
    overflow: auto;
  }

  &__body {
    padding-bottom: 0px;
    white-space: pre-line;
  }

  &__icon {
    display: inline-block;
  }

  &__button {
    @include button-warning;
  }

  &__header {
    border-bottom: 1px solid $color-brown;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    font-weight: $font-weight--bold;
    font-size: 20px;

    &--text {
      padding-left: 10px;
      height: 35px;
      vertical-align: middle;
      display: inline-block;
    }
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &__confirmation-btns {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 12px 0 0;

    &--confirm {
      @include button-warning;
      margin: 0;
    }

    &--cancel {
      @include button-success-no-border;
      margin: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .modal__container {
    max-width: 55%;
    padding: 1rem;
  }
  .modal__body {
    font-size: 14px;
  }
  .modal__header {
    font-size: 17px;
  }
}
