@import '../../common/common';

.toggle-switch {
  label {
    width: 100%;
    background-color: $color-grey-30;
    position: relative;
    cursor: pointer;
  }

  .toggle {
    position: absolute;
    background-color: $color-white;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  label,
  .toggle {
    display: flex;
    align-items: center;
    height: 1.9rem;
    border-radius: 100px;
  }

  .options {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: space-around;
    align-items: center;
    user-select: none;
    transition: 0.9s ease all;

    p {
      @include primary-text;
      color: $color-green;

      &.on {
        color: $color-green;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: fadeIn 0.5s;

        &::before {
          content: '';
          display: block;
          height: 8px;
          width: 8px;
          border-radius: 50%;
          background-color: $color-green;
          margin-right: 5px;
        }
      }

      &.off {
        color: $color-white;
      }
    }
  }

  input[type='checkbox'] {
    display: none;

    &:not(:checked) + .content .toggle {
      width: 55%;
      transition: 0.5s ease;
      margin-left: 2px;
      height: 1.6rem;
    }

    &:checked + .content .toggle {
      width: 49.5%;
      transform: translateX(100%);
      height: 1.6rem;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
