@import '../../../common/_variables.module.scss';
@import '../../../common/_buttons.scss';

$font-size: 14px;

.search {
  &__search-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 40px 5px;

    .MuiInputBase-root {
      width: 17rem;
    }
  }

  &__duration-list-item {
    justify-content: center !important;
  }
  &__icon {
    padding-left: 12px;
  }

  &__container {
    padding-bottom: 20px;
  }

  &__content {
    background: $color-white;
    border: 1px solid $color-grey-10;
    border-radius: 10px;
    margin-bottom: 20px;
    width: 100%;
    margin: 0 auto;
    position: relative;

    .form-group {
      width: 100%;
      padding-bottom: 15px;
    }

    p {
      color: #bf1650;
      margin: 4px 0 0 0;
      font-size: 13px;
    }

    p::before {
      display: inline;
      content: '* ';
    }

    &--left {
      margin-right: 35px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 30%;
    }

    &--right {
      border-left: 1px solid $color-border;
      padding-left: 20px;
      width: 70%;
    }
  }

  &__dropdown-list-item {
    justify-content: space-between !important;
    word-wrap: break-word !important;
    white-space: normal !important;
    line-height: 21px !important;
    border-bottom: 1px solid $color-grey-5 !important;
    font-size: $font-size !important;

    &:last-child {
      border-bottom: none !important;
    }
  }

  &__search-name {
    display: block;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid $color-border;
    padding: 10px 15px;
    font-size: $font-size;
    width: 100%;

    .MuiOutlinedInput-input {
      padding: 9px;
    }

    .MuiOutlinedInput-root {
      border-radius: 10px !important;
    }

    label {
      display: flex;
      align-items: center;
      height: 0;
      font-size: 14px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    padding: 15px 0px;
    width: 100%;
  }

  &__status__picker {
    display: flex;
    justify-content: space-between;

    &-left {
      width: 50%;
    }

    &-right {
      width: 50%;
    }
  }

  &__date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    .date input,
    .date-picker input {
      color: $color-dark-grey;
      padding: 9px 4px 9px 12px !important;
      height: 0.9em !important;
    }

    .day-picker__checkbox + .day-picker__label {
      height: 28px;
      width: 28px;
      margin-right: 10px;
      line-height: 28px;
    }

    .MuiFormControl-root {
      margin-top: 0;
      margin-bottom: 0;
      width: 100%;
      text-align: center;
    }

    .day-picker {
      margin: 0 !important;
    }

    .date-container div div.MuiOutlinedInput-root,
    .date div div.MuiOutlinedInput-root {
      width: 100%;
    }
  }

  &__button-search {
    margin: 0;
  }

  &__button-search-options {
    width: 40%;

    button {
      margin: 0 !important;
      width: 100%;
    }
  }

  &__button-save-options {
    display: flex;
  }

  &__content-close {
    position: absolute;
    right: 12px;
    top: 12px;
  }

  &__content-form {
    display: flex;
    padding: 25px 35px;

    .status-picker {
      width: 100%;
      margin-top: 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 5px;
      padding: 10px 0px;
    }
  }

  &__button-search,
  &__button-save-as {
    @include button-success-with-border;
    margin-right: 12px;
    border: none !important;
  }

  &__button-save {
    @include button-success;
  }

  &__button-add-search {
    @include button-success-with-border;
    margin: 0;
    width: 150px;
    border: none;
    justify-content: left;
  }

  &__other-actions {
    position: absolute;
    right: 30px;

    .search__reset {
      position: initial;
      color: $color-brown;

      &--enable {
        color: $color-brown;
      }

      &--disable {
        color: $color-grey;
        opacity: 0.6;
        cursor: not-allowed;
        pointer-events: none;
      }
    }

    .search__delete {
      color: $color-warning;

      &--enable {
        color: $color-warning;
      }

      &--disable {
        color: $color-grey;
        opacity: 0.6;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }

  &__button {
    &--disable {
      @include button-disable;
    }
  }

  &__additional-search-row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px 0px;

    &-left {
      width: 70%;
      display: flex;
      justify-content: space-between;
    }

    &-right {
      width: 25%;
      display: flex;
      align-items: center;
      button {
        padding: 0px !important;
      }
    }

    .MuiSelect-select {
      padding: 7px 32px 7px 12px;
      min-height: 0 !important;
    }

    .MuiOutlinedInput-root {
      border-radius: 10px;
    }
  }

  &__additional-search-input {
    box-sizing: border-box;
    border-radius: 10px;
    font-size: $font-size;
    width: 6rem;
    margin-right: 15px !important;

    .MuiInputBase-input {
      padding: 7px;
    }
  }

  &__additional-search {
    align-items: center;
  }

  &__additional-search-select {
    width: 30%;
  }

  .MuiInputBase-input {
    padding: 7px;
  }

  .additional-search-action {
    width: 10%;

    button {
      padding: 0px !important;
    }

    .MuiIconButton-root {
      padding: 0px !important;
    }
  }

  &__content-date {
    width: 23%;
  }

  &__content-weekday {
    width: 45%;
  }

  &__content-days {
    display: flex;
    padding: 0px 15px;
    align-items: center;
    width: 25%;

    .dropdownMenu__container {
      width: 50%;
    }

    .MuiInputBase-root {
      width: 5rem;
    }

    span {
      color: $color-dark-grey;
    }
  }

  &__dropdown-placeholder {
    color: $color-grey-50;
  }

  &__duration {
    .MuiSelect-select {
      padding: 7px 32px 7px 12px;
      min-height: 0 !important;
    }

    .MuiOutlinedInput-root {
      border-radius: 10px;
      margin-right: 5px;
    }
  }

  &__footer {
    display: flex;
    padding: 10px 0px;
  }

  .MuiSelect-select {
    padding: 7px 32px 7px 12px;
    display: inherit;
    justify-content: space-between;
    align-items: center;
    font-size: $font-size;
    min-height: 0;

    .icon {
      vertical-align: bottom;
      color: $color-brown;
    }

    .label {
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-root {
    border-radius: 10px !important;
  }
}

.MuiList-root .MuiList-padding .MuiMenu-list {
  max-height: 15rem !important;
}

.dropdownMenu__button {
  color: $color-green !important;

  button {
    @include button-success-no-border;
    width: 100%;
  }
}
