@import '../../../../common/common';

.inventory-rate-level-widget .content {
  padding: 28px 32px 0 28px;
  font-size: 0.75rem;

  .view-select {
    margin-bottom: 16px;
    height: 1.75rem;
    border-radius: 16px !important;
    color: $color-grey-80;
  }

  .no-data {
    display: block;
    font-size: 1rem;
    color: $color-grey-60;
    margin-top: 1rem;
  }

  .inventory-container {
    margin-top: 16px;

    .label {
      margin-bottom: 6px;
    }

    .bar-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;

      .bar {
        display: inline-block;
        position: relative;
        width: 90%;

        div {
          position: absolute;
          top: calc(50% - 4px);
          width: 100%;
          height: 8px;
          border-radius: 4px;

          &.total {
            overflow: hidden;
            background-color: $color-grey-10;
          }

          &.group-blocked {
            background-color: rgba($color-orange, 0.4);
          }
          &.group-booked {
            background-color: rgba($color-orange, 0.7);
          }
          &.out-of-order {
            background-color: rgba($graph-darker-grey, 0.7);
          }
          &.transient-booked {
            background-color: rgba($graph-light-blue, 0.7);
          }
          &.physical-cap {
            background-color: $graph-pink;
          }
        }
      }
    }
  }

  .liveCell {
    display: inline-block;
    position: relative;
  }
  .liveCell__star {
    color: $color-green;
    font-size: 16px;
    position: absolute;
    right: -4px;
    bottom: 0;
  }
  .liveCell__value {
    margin-right: 4px;
  }

  .ag-theme-alpine {
    --ag-font-size: 12px;
    --ag-header-background-color: #fff;
    --ag-odd-row-background-color: #fafafa;
    --ag-borders: none;
    --ag-row-border-style: none;
    --ag-row-height: 31px;
    --ag-cell-horizontal-padding: 0;

    height: 300px;

    .ag-header-row-column {
      border-top: 1px solid rgba($color-grey, 0.08);
      border-bottom: 1px solid rgba($color-grey, 0.08);
    }

    .ag-header-row {
      font-weight: $font-weight--regular;
      text-align: center;
      white-space: break-spaces;
    }
    .ag-header-cell-label {
      color: $color-grey-70;
      justify-content: center;
    }
    .ag-row {
      font-size: 11px;
      color: $color-grey-60;
      text-align: center;
    }
  }
}
