@import '../../common/common';

.rounding-rules {
  display: flex;
  align-items: center;

  .rounding-form {
    display: flex;
    align-items: center;
    color: $color-dark-grey;
    margin: 10px;
  }

  .rule-select {
    border: 1px solid rgba(72, 72, 72, 0.2);
    border-radius: 10px;
    margin-left: 20px;
  }

  .MuiInputBase-root {
    height: 35px;
    width: 95px;
  }

  .MuiButtonBase-root {
    height: 20px;
  }
}

.ag-root-wrapper {
  border-radius: 12px;
  font-family: $font-family-main;
  text-align: center;
}

.ag-header-cell-label {
  justify-content: center;
}

.rules-table {
  border-radius: 12px;
  max-width: 968px;
  overflow: auto;
  height: 500px;

  .ag-header {
    background-color: $color-grey-65;
    text-align: center;
  }

  .ag-header-row {
    color: $color-white;
    font-weight: $font-weight--regular;
    font-size: 15px;
    white-space: break-spaces;
  }
}
