@import '../../../common/common.scss';

.article-editor-container {
  .form-row-inline {
    display: flex;
    margin-right: 10px;
    flex-wrap: nowrap;
    gap: 10px;
    height: 30px;
  }

  .input-container label {
    margin-right: 10px;
    font-weight: bold;
    white-space: nowrap;
    flex: 0 0 auto;
  }

  .input-container input {
    flex-grow: 1;
    padding: 3px;
    font-size: 16px;
    border: 1px solid $color-grey-main;
    border-radius: 4px;
  }

  .input-container select {
    flex-grow: 1;
    padding: 3px;
    font-size: 16px;
    border: 1px solid $color-grey-main;
    border-radius: 4px;
  }

  .editor-wrapper {
    margin-top: 10px;
  }

  .submit-button {
    color: $color-green;
    background-color: $color-white;
    max-height: 36px;
    font-size: 100%;
    font-weight: 800;
    line-height: 36px;
    width: 100px;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid $color-green;
  }

  .submit-button:hover {
    color: $color-white;
    background-color: $color-green;
  }
}
