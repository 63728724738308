@import '../../common/_variables.module.scss';
@import '../../common/_common.scss';

.events-container {
  width: 97%;
  margin-top: 80px;

  .error {
    position: relative;

    .MuiSnackbar-root {
      top: 0px !important;
      right: auto !important;
      left: 0px !important;
      position: absolute !important;
    }
  }

  .left {
    display: flex;
    align-items: center;
  }

  .btns-container {
    display: flex;
    width: 35%;
    justify-content: center;
    padding: 0px 50px;
    box-sizing: border-box;
    .save {
      @include primary-text;
      @include button-success;
      margin: 72px 0px 0px 0px;
      color: $color-green;
      background-color: $color-white;

      &:hover:enabled {
        color: $color-white;
        background-color: $color-green;
      }
    }
  }
}
