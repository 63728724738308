@import '../../common/_variables.module.scss';
.sort-btn {
  &__label {
    .Joy-checked span {
      background: $color-green;
      color: $color-white;
      height: 16px;
      border-radius: 50%;
    }
    .Joy-checked + label {
      color: $color-white;
    }
  }
}
