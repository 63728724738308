@import '../../../common/_common.scss';

.DraftEditor-root {
  background-color: $color-white;
  padding: 10px;
  border-radius: 4px;
  min-height: 400px;
}

.DraftEditor-editorContainer,
.public-DraftEditor-content {
  min-height: 400px;
}

.public-DraftEditor-content[contenteditable='true'] {
  -webkit-user-modify: read-write-plaintext-only;
}

.public-DraftStyleDefault-block {
  margin: 5px;
}

.public-DraftEditorPlaceholder-root {
  position: absolute;
  top: 16px;
  left: 15px;
}

.text-editor-container {
  .editorClassName {
    border: 1px solid $color-green;
    border-radius: 10px;
    padding: 3px;

    &:hover {
      border: 2px solid $color-green;
      padding: 2px;
    }

    &:focus-within {
      border: 2px solid #000000af;
      padding: 2px;
    }
  }
}
