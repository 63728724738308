@import '../../../../common/common';

.season-select.MuiOutlinedInput-root {
  width: 100%;
  border-radius: 10px;

  .MuiSelect-select {
    @include primary-text;
    color: $color-grey-70;
    padding: 0.5rem 2rem 0.5rem 0.75rem;
    min-height: 0;
  }
}
