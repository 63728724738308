@import '../../common/_variables.module.scss';

.header {
  &__left {
    display: flex;
    width: 100%;
    padding: 0 20px;
    align-items: center;
    border-radius: 10px;
    justify-content: space-between;
  }

  &__left.user {
    background-color: $color-grey-6;
  }

  &__left.no-user {
    background-color: $color-white;
  }

  &__left-wrapper {
    display: flex;
    flex-grow: 1;
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__divider {
    border-right: 1px solid $color-grey-6;
    margin-right: 18px;
    padding-right: 15px;
  }

  &__company-info {
    display: flex;
    align-items: center;
    padding: 15px 0;
  }

  &__company-name {
    padding-left: 10px;
    color: $color-logo;
    font-weight: $font-weight--regular;
  }

  &__icon {
    cursor: pointer;
    size: 20px;
  }

  &__icon-disable {
    color: $color-grey-65;
    cursor: not-allowed;
    pointer-events: none;
    size: 20px;
  }

  &__icon-enable {
    color: $color-warning;
  }

  &__property {
    display: flex;
    align-items: center;
  }

  &__flag-icon {
    size: 20px;
    color: #eb3223;
  }
  .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
    padding: 0 !important;
  }

  &.alt-width {
    position: relative;
    z-index: 20;
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 1rem;

    .filler-el {
      min-width: $overview-panel-min-width;
    }
  }
  &__icon-sellout-cnt {
    background: #eb3223;
    color: $color-white;
    font-size: 12px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    align-items: center;
    display: inline-flex;
    position: absolute;
    top: -8px;
    left: 21px;
    justify-content: space-around;
    padding: 0.5px;
  }
}

.user-details {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  font-weight: bold;

  .login-btn {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  &__name {
    font-size: 20px;
    color: $color-grey;
    margin: 0;
    font-weight: bold;
  }
}

.user-details.user {
  background-color: $color-grey-6;
}

.user-details.no-user {
  background-color: $color-white;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover,
.css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: unset !important;
}

.header__icons {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  a,
  .MuiButtonBase-root {
    color: rgba(0, 0, 0, 0.54);
  }
}

@media screen and (max-width: 992px) {
  .header__icons .MuiButtonBase-root {
    padding: 0 3px;
  }
  .header__icon {
    flex-basis: 80%;
  }

  .header__flag-icon {
    flex-basis: 80%;
  }
  .__icon-disable {
    flex-basis: 80%;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    &.alt-width {
      position: relative;
      z-index: 20;
      display: grid;
      grid-template-columns: 1fr;
      gap: 1rem;
    }
    &__icons {
      display: none;
    }
    &__left {
      width: 80%;
      padding: 0;
    }
    &__left-wrapper {
      width: 100%;
    }
    &__left.user {
      background-color: $color-white;
    }
    &__company-info {
      width: 10%;
    }
    &__divider {
      width: 5%;
      border-right: 0;
      margin-right: 0;
      padding: 0;
    }
    &__property {
      width: 85%;
    }
    &__right {
      width: 18%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .filler-el {
    display: none;
  }
}
