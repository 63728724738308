@import '../../common/common';

.generic-fallback {
  margin: 3rem;

  .message {
    max-width: 35rem;
  }

  .reset-btn {
    padding: 0;
    text-transform: none;
    font-size: 16px;
    height: 19px;
    padding-bottom: 2px;
    color: $color-green;
  }
}
