@import '../../../../common/common';

@mixin button {
  text-transform: none;
  font-size: 0.75rem;
  color: $color-grey;
  height: 18px;
  border-color: $color-grey-35;
  border-radius: 10px;

  &:hover {
    border-color: $color-grey-80;
  }
}

.date-feed {
  z-index: 30;
  background: #fff;
  position: absolute;
  height: 800px;
  width: calc(100% - 16px);
  top: 432px;
  transition: top 0.5s ease;

  &.expanded {
    top: 8px;
    // overflow-y: scroll;
    .expand-btn {
      transform: rotate(180deg);
    }
  }

  & .card-list {
    max-height: 95%;
    overflow-y: scroll;
  }

  .btn {
    @include button;
  }

  .controls {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 8px;

    .icon-btn {
      padding: 0;
    }
  }
}

.day-card {
  margin-bottom: 8px;

  .content {
    box-sizing: border-box;
    padding: 12px;

    .visible-on-focus {
      display: none;
      position: absolute;
      left: -30px;
    }
    &:hover .visible-on-focus {
      display: inherit;
    }

    .btn {
      @include button;
    }

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      .left-section {
        display: flex;
        align-items: center;
        height: 16px;

        .icon-btn {
          width: 16px;
          margin-left: 6px;
          margin-right: 6px;
          padding: 0;

          &.group-icon {
            width: 24px;
          }
        }
        .priority-btn {
          width: 12px;
          height: 12px;
          margin-left: 0;
          align-self: flex-end;
        }
        .date-btn {
          text-transform: none;
          font-size: 0.75rem;
          height: 0.75rem;
          color: $color-grey-60;
          margin-top: auto;
          padding: 0;

          &:disabled {
            color: $color-brown;
            font-weight: 700;
          }
        }
      }
    }

    .rec-status-btn {
      background: #e3f3ff;
      &:hover {
        background: darken(#e3f3ff, 10);
      }
    }

    .row {
      display: flex;
      justify-content: space-between;
      font-size: 0.75rem;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .field-right {
      text-align: right;
    }

    .rate-override-field {
      position: relative;

      .icon-btn {
        padding: 0;
        height: 18px;
        font-size: 1rem;
        &:not(:disabled) svg {
          color: $color-brown;
        }
      }
      .override-input {
        width: 64px;
        text-align: right;
        background: rgba(255, 212, 100, 0.3);
        border-radius: 5px;
        border: none;
        font-size: 0.75rem;
      }
    }

    .label {
      color: #7f8ea5;
      margin: 0;
      margin-bottom: 4px;
    }

    .liveCell {
      display: inline-block;
      position: relative;
    }
    .liveCell__star {
      color: $color-green;
      font-size: 16px;
      position: absolute;
      right: -4px;
      bottom: 0;
    }
    .liveCell__value {
      margin-right: 4px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .date-feed {
    &.expanded {
      top: 115px;
    }
    top: 395px;
  }
}
