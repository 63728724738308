@import '../../../common/common';

.rate-upload-modal {
  ul {
    @include scroll-shadows;
    list-style: none;
    padding: 0;
    max-height: 15rem;
    overflow: scroll;
  }
  li {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .modal__confirmation-btns {
    &--cancel {
      color: $color-warning;
    }
    &--confirm {
      border-color: $color-green;
      background-color: $color-green;
    }
  }
}

.loader {
  animation-name: stretch;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;
}
.loader::after {
  animation-delay: 1s;
}

@keyframes stretch {
  0% {
    transform: scale(0.7);
    border-radius: 100%;
  }

  100% {
    transform: scale(1.3);
    background-color: rgb(85, 198, 82, 0.2);
  }
}
