@mixin clickable {
  cursor: pointer;
  user-select: none;
}
@mixin button {
  @include clickable;
  font-size: 14px;
  height: 2rem;
  border: 1px solid;
  width: 110px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  border-radius: 8px;
  color: $color-white;
  justify-content: center;
  text-decoration: none;
}

@mixin button-warning {
  @include button;
  background-color: $color-warning;
  border: 1px solid $color-warning;
}

@mixin button-success {
  @include button;
  background-color: $color-green;
  border: 1px solid $color-green;
}

@mixin button-success-no-border {
  @include button;
  color: $color-green;
  background-color: $color-white;
  border: none;
}

@mixin button-success-with-border {
  @include button;
  color: $color-green;
  background-color: $color-white;
  border: 1px solid $color-green;
}

@mixin button-warning-no-border {
  color: $color-warning;
}

@mixin button-no-style {
  background: none;
  border: none;
  padding: 0;
  text-align: center;
  cursor: pointer;
}

@mixin button-disable {
  color: $color-white;
  background: $color-grey;
  border: 1px solid $color-grey;
  cursor: not-allowed;
  opacity: 0.3;
  pointer-events: none;
}
