@import '../../../../common/common';

.forecast-info-tooltip {
  .content {
    padding: 16px;
    color: $color-grey-80;
    width: 250px;

    h1 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
      color: black;
    }

    span {
      font-size: 14px;
    }

    .last-updated {
      margin-top: 4px;
    }

    .recommendation {
      font-size: 14px;
      font-weight: bold;
      margin: 0;
      display: flex;
      align-items: center;

      &.inc {
        color: #4caf50;
      }

      &.dec {
        color: #f44336;
      }

      .icon {
        margin-right: 8px;
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      gap: 8px;

      display: flex;
      justify-content: space-between;

      h2 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 8px;
        margin-top: 0px;
        border-bottom: 1px solid $color-grey-20;
        color: black;
      }

      h3 {
        font-weight: 700;
        color: $color-dark-grey-80;
      }

      h4 {
        font-size: 14px;
        font-weight: bold;
        color: $color-dark-grey;
        margin: 8px 0 4px 0;
      }

      .row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
      }

      .value {
        font-weight: bold;
      }

      .rates {
        display: flex;
        justify-content: space-between;
        .rate-container {
          h3 {
            font-size: 14px;
          }
          display: flex;
          flex-direction: column;
          .value {
            font-size: 18px;
            color: black;
          }
        }
      }

      .ratios {
        font-size: 14px;
        .row {
          align-items: center;
        }
        .badge {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: $color-grey-10;
          border-radius: 9999px;
          padding: 4px 8px;
          .up {
            color: $color-green;
          }
          .down {
            color: red;
          }
        }
      }

      .pickup {
        display: flex;
        flex-direction: column;
        .value {
          margin-left: 4px;
        }
      }

      .reasons {
        ul {
          list-style-type: disc;
          padding-left: 20px;

          li {
            margin-bottom: 4px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
